import React from "react";

const BranchText = () => {
  return (
    <div className="uppercase font-semibold">
      <span className="text-[#231F20]">Gospel</span>{" "}
      <span className="text-[#7B7C7F]">Truth</span>
    </div>
  );
};

export default BranchText;
