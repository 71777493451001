/* eslint-disable no-unused-vars */
import styles from "./CopyBtn.module.css";
import { Fragment, useState } from "react";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { unified } from "unified";
import markdown from "remark-parse";
import docx from "remark-docx";
import { IconDownload } from "./Icon";

const ExportToDocBtn = ({ content, name }) => {
  const [loading, setLoading] = useState(false);

  const exportToGoogleDoc = async () => {
    const fileName = name
      ? `${name.slice(0, 30).split(" ").join("-")}`
      : "document.docx";

    const callAPI = async () => {
      setLoading(true);
      try {
        const processor = unified().use(markdown).use(docx, { output: "blob" });

        const doc = await processor.process(content);
        const blob = await doc.result;
        saveAs(blob, fileName);

        toast.success("Successfully");
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callAPI();
  };

  return (
    <Fragment>
      <div
        onClick={(e) => exportToGoogleDoc()}
        className={`${styles["copy-icon"]} !right-20 ${styles["docx-icon-one"]}`}
      >
        <IconDownload className="size-5" />
      </div>
    </Fragment>
  );
};

export default ExportToDocBtn;
