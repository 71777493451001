import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { TIME_INTERVAL_TYPEWRITER } from "../constants/index";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// output 03/02/2024 03:22 PM
export const formatDateToCustomString = (date) => {
  if (!date) date = new Date();
  date = new Date(date);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = formatter.format(date);

  return formattedDate.replace(",", "");
};

export const calculateTimeTypewriter = (maxLength) => {
  return maxLength * TIME_INTERVAL_TYPEWRITER;
};
