import { useState, useEffect, useMemo } from "react";
import { chatAction } from "../../../store/chat";
import { useDispatch, useSelector } from "react-redux";
import { CodeBlock } from "../../Chat/CodeBlock";
import { MemoizedReactMarkdown } from "../../Chat/Markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import remarkHtml from "remark-html";
import emitter from "../../../utils/events";

const ReplyByGemini = ({ isNewChat, ...props }) => {
  const { gemini, scrollHeight } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const isStoped = useSelector((state) => state.chat.isStoped);
  const messageLength = useSelector((state) => state.chat.messageLength);

  const [showLoadingText, setLoadingText] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNewChat) return;
    let intervalId;
    if (isStoped) {
      dispatch(chatAction.setIsReplying(false));

      return clearInterval(intervalId);
    }
    if (messageLength && currentIndex >= messageLength) {
      dispatch(chatAction.setIsReplying(false));

      return clearInterval(intervalId);
    }

    if (messageLength && currentIndex >= messageLength) {
      dispatch(chatAction.setIsReplying(false));
      return clearInterval(intervalId);
    }
    intervalId = setInterval(() => {
      if (currentIndex < gemini?.length) {
        setCurrentIndex(currentIndex + 1);
        if (currentIndex % 500 === 0) {
          scrollHeight(currentIndex);
        }
      }
    }, 4);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gemini, currentIndex]);

  useEffect(() => {
    if (isNewChat) {
      if (!gemini) {
        setLoadingText(1);
        setTimeout(() => {
          setLoadingText(2);
          // setTimeout(() => {
          //   setLoadingText(0);
          // }, 10000);
        }, 7000);
      } else {
        setLoadingText(0);
      }
    }
  }, [gemini, isNewChat, isStoped]);

  const preMessage = useMemo(() => {
    if (isNewChat && !gemini) {
      if (isStoped) {
        emitter.emit("abort-chat");
        return <></>;
      }
      return (
        <>
          {showLoadingText === 1 && (
            <div className="animate-fadeIn delay-500">
              <div className="ml-5 w-full animate-pulse delay-500 flex items-center ">
                <p className="animate-fadeOut !font-semibold text-gray-500">
                  Memory Update
                </p>
              </div>
            </div>
          )}
          {showLoadingText === 2 && (
            <div className="animate-fadeIn delay-500">
              <div className="ml-5 w-full animate-pulse delay-500 flex items-center ">
                <p className="!font-semibold text-gray-500">Please wait ...</p>
              </div>
            </div>
          )}
        </>
      );
    }
    return <></>;
  }, [isStoped, isNewChat, gemini, showLoadingText]);

  return (
    <div className="w-full">
      {preMessage}
      <MemoizedReactMarkdown
        className="w-full text-start prose break-words dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 px-3"
        remarkPlugins={[remarkGfm, remarkMath, remarkHtml]}
        components={{
          li({ children }) {
            return <li className="my-3 last:mb-0">{children}</li>;
          },
          p({ children }) {
            return (
              <p className="whitespace-pre-wrap my-3 last:mb-0">{children}</p>
            );
          },
          code({ node, inline, className, children, ...props }) {
            if (children && children.length) {
              // eslint-disable-next-line eqeqeq
              if (children[0] == "▍") {
                return (
                  <span className="mt-1 cursor-default animate-pulse">▍</span>
                );
              }
              if (Array.isArray(children)) {
                children[0] = children[0].replace("`▍`", "▍");
              }
            }

            const match = /language-(\w+)/.exec(className || "shell");

            if (!node.properties.className || inline) {
              return (
                <code className={`${className ? className : ""}`} {...props}>
                  {children}
                </code>
              );
            }

            return (
              <CodeBlock
                key={Math.random()}
                language={(match && match[1]) || ""}
                value={String(children).replace(/\n$/, "")}
                {...props}
              />
            );
          },
        }}
      >
        {isNewChat ? gemini?.slice(0, currentIndex) : gemini}
      </MemoizedReactMarkdown>
    </div>
  );
};

export default ReplyByGemini;
