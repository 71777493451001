import axios from "axios";

export function configAxiosDefault() {
  const axiosService = axios;

  axiosService.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err?.response?.status === 401) {
        window.location.href = "/login";
      } else if (err?.response?.status === 403) {
        window.location.href = "/";
      } else {
        return Promise.reject(err);
      }
    }
  );

  const axiosDefault = axiosService.defaults;
  axiosDefault.baseURL = process.env.REACT_APP_SERVER_ENDPOINT;

  axiosDefault.withCredentials = true;
  axiosDefault.headers.common["Content-Type"] = "application/json";

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  axiosDefault.headers.common["Time-Zone"] = timeZone;
  return axiosService;
}
