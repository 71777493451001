/* eslint-disable jsx-a11y/anchor-is-valid */
// src/EmailVerificationSuccess.js
import { useEffect, useState } from "react";
import LoadingOverlay from "../../components/Ui/LoadingOverlay";
import { verifyEmail, resendVerifyEmail } from "../../store/auth-action";

import { useLocation, useNavigate } from "react-router-dom";

const EmailVerificationSuccess = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get("email");
  const code = searchParams.get("code");

  useEffect(() => {
    console.log(email, code);
    const handleRedirect = async () => {
      try {
        setIsLoading(true);
        await verifyEmail(email, code);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    handleRedirect();
  }, [email, code]);

  const handleResendEmail = async (e) => {
    try {
      setIsLoading(true);
      await resendVerifyEmail(email);
      setIsLoading(false);
      setTimeout(() => {
        navigate("/ask-verification?email=" + email);
      }, 500);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          {!isError ? (
            <div className="p-8 max-w-xl mx-auto">
              <h1 className="text-2xl font-bold text-blue-600 mb-4">
                Email Verified Successfully!
              </h1>
              <p className="text-gray-700 mb-6">
                Thank you for verifying your email address. Your account is now
                active.
              </p>
              <a
                href="/login"
                className="bg-slate-300 text-black py-2 px-4 rounded hover:bg-slate-200"
              >
                Login now
              </a>
            </div>
          ) : (
            <div className="p-8 max-w-xl mx-auto">
              {/* <h1 className="text-2xl font-bold text-blue-600 mb-4">
              </h1> */}
              <p className="text-gray-700 mb-6">
                Your verification link has expired or is invalid, please click{" "}
                <button
                  className="text-blue-600 hover:underline"
                  onClick={(e) => handleResendEmail(e)}
                >
                  here
                </button>{" "}
                to verify your email again.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EmailVerificationSuccess;
