import "./style.css";
import AdminSidebar from "../../components/Admin/Sidebar/AdminSidebar";
import AdminHeader from "../../components/Admin/Header/AdminHeader";
import AdminMainSection from "../../components/Admin/AdminSection/AdminMainSection";
import { useSelector, useDispatch } from "react-redux";
import UserDetails from "../../components/UserDetails/UserDetails";
import { useEffect } from "react";
import { uiAction } from "../../store/ui-gemini";

const AdminHomePage = () => {
  const dispatch = useDispatch();
  const isUserDetails = useSelector((state) => state.ui.isUserDetailsShow);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const isDark = useSelector((state) => state.ui.isDark);

  useEffect(() => {
    const getLocalTheme = localStorage.getItem("theme");
    const theme = getLocalTheme || "light";
    document.documentElement.setAttribute("data-theme", theme);
  }, [isDark]);

  const userDetailsOpen = () => {
    dispatch(uiAction.toggleUserDetailsShow());
  };

  return (
    <>
      <AdminHeader />
      <div className="admin-wrap mt-20">
        <AdminSidebar />
        <AdminMainSection />
      </div>

      {isUserDetails && isLogin && <UserDetails />}

      {isUserDetails && isLogin && (
        <div onClick={userDetailsOpen} className="bg-focus-dark"></div>
      )}
    </>
  );
};

export default AdminHomePage;
