import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminHomePage from "./pages/admin/Home";
import Users from "./pages/admin/user/User";
import AdminSubscriptions from "./pages/admin/subscription/Subscription";
import AdminPlan from "./pages/admin/plan/Plan";
import AdminSetting from "./pages/admin/settings/Setting2";
import GoogleAuthHandleRedirect from "./components/Auth/GoogleAuthHandleRedirect";

import LoginForm from "./pages/login/Login2";
import SignupForm from "./pages/signup/Signup2";
import VerifyEmail from "./pages/signup/VerifyEmail";
import VerifyNotify from "./pages/signup/VerifyNotify";
import Subscriptions from "./pages/subscription/Subscription";
import Privacy from "./pages/policy/Policy";
import Term from "./pages/policy/Term";
import ScrollChat from "./components/NewChat/ScrollChat/ScrollChat";
import NewChat from "./components/NewChat/NewChat";
import { AuthMiddleware } from "./components/Auth/AuthMiddleware";
import SubscriptionLayout from "./components/Layout/Subscription";
import Share from "./pages/share/Share";

import store from "./store";
import { configAxiosDefault } from "./utils/axios";

configAxiosDefault();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <Helmet>
        <title>Gospel Truth AI</title>
        <meta name="description" content="Gospel Truth AI" />
        <link rel="canonical" href={process.env.REACT_APP_DOMAIN} />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthMiddleware component={<App />} />}>
            <Route index element={<NewChat />} />
            <Route path="app" element={<ScrollChat />}></Route>
            <Route path="app/:historyId" element={<ScrollChat />}></Route>
          </Route>
          <Route
            path="/admin"
            element={<AuthMiddleware component={<AdminHomePage />} />}
          >
            <Route path="" element={<Users />} />
            <Route path="user" element={<Users />} />
            <Route path="plan" element={<AdminPlan />} />
            <Route path="subscription" element={<AdminSubscriptions />} />
            <Route path="setting" element={<AdminSetting />} />
          </Route>
          <Route
            path="/return/auth/google"
            element={<GoogleAuthHandleRedirect />}
          />

          <Route path="/term" element={<SubscriptionLayout hideLogin={true} />}>
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="terms-of-service" element={<Term />} />
          </Route>

          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/email-verification" element={<VerifyEmail />} />
          <Route path="/ask-verification" element={<VerifyNotify />} />
          <Route
            path="/share"
            element={<SubscriptionLayout hideLogin={true} />}
          >
            <Route path=":messageId" element={<Share />}></Route>
          </Route>

          <Route
            path="/subs"
            element={<AuthMiddleware component={<SubscriptionLayout />} />}
          >
            <Route path="" element={<Subscriptions />}></Route>
          </Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);
