import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import React, { useRef, useEffect, Fragment, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import styles from "../../components/NewChat/ScrollChat/ScrollChat.module.css";
import { commonIcon } from "../../asset";
import { getShareMessage } from "../../store/chat-action";
import ReplyByGemini from "../../components/NewChat/ScrollChat/ReplyByGemini";
import CopyBtn from "../../components/Ui/CopyBtn";
import LoadingOverlay from "../../components/Ui/LoadingOverlay";
import BranchText from "../../components/Ui/BranchText";
import { formatDateToCustomString } from "../../utils/utils";
import { toast } from "react-toastify";

const Share = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessages] = useState();
  const dispatch = useDispatch();
  const { messageId } = useParams();
  const chatRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getShareMessage(messageId);
        setTimeout(() => {
          setLoading(false);
        });
        setMessages(response.data.data);
      } catch (error) {
        setLoading(false);

        toast.error(error?.response?.data?.message);
      }
    };
    fetchData();
  }, [messageId, dispatch]);

  const messageSection = useMemo(() => {
    if (!message) return <></>;
    return (
      <Fragment>
        <div className={`${styles["single-chat"]}`}>
          <div className={styles["gemini"]}>
            <img
              src={commonIcon.chatGeminiIcon}
              alt="avater icon"
              className={`border border-slate-200 rounded-full h-8 w-8 p-[4px]`}
            ></img>
            <ReplyByGemini
              gemini={message?.message?.gemini}
              isNewChat={false}
            />
          </div>
          {message?.message?.gemini?.length > 0 && message.role !== "user" && (
            <CopyBtn data={message?.message?.gemini} />
          )}
        </div>
      </Fragment>
    );
  }, [message]);

  const meta = useMemo(() => {
    const title = message?.message?.user;
    const description = message?.message?.gemini?.slice(0, 150).split(" ");
    return (
      <Helmet>
        <meta name="robots" content="index,follow" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={window?.location?.href} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={window?.location?.href} />
        <meta property="og:site_name" content="Gospel Truth AI" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={window?.location?.href} />

        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
      </Helmet>
    );
  }, [message]);

  return (
    <div className="flex flex-col items-center justify-center bg-gray-50">
      {meta}
      <div
        className={`${styles["scroll-chat-main"]} !h-auto mb-3`}
        ref={chatRef}
      >
        <div className="w-full max-w-2xl justify-start mx-auto my-5">
          <h1 className="text-4xl mb-1">
            {message ? message.message?.user : ""}
          </h1>
          <div className="inline-flex gap-2 text-sm">
            <span>Posted by </span>
            <BranchText /> <span>at</span>
            <span>
              {message ? formatDateToCustomString(message.timestamp) : ""}
            </span>
          </div>
        </div>
        {messageSection}
      </div>
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default Share;
