import { createSlice } from "@reduxjs/toolkit";

const tellMemore = "Tell me more";

const inittalState = {
  chatType: "private", // private, public
  messages: [],
  chats: [],
  relatedQuestions: [tellMemore],
  newChat: false,
  isLoader: false,
  isStoped: false,
  recentChat: [],
  previousChat: [],
  chatHistoryId: "",
  suggestPrompt: "",
  messageLength: 0,
  isReplying: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState: inittalState,
  reducers: {
    setChatType(state, action) {
      state.chatType = action.payload;
    },
    setMessageLength(state, action) {
      state.messageLength = action.payload;
    },
    setIsStoped(state, action) {
      state.isStoped = action.payload;
    },
    setIsReplying(state, action) {
      state.isReplying = action.payload;
      state.isStoped = false;
      state.messageLength = 0;
    },
    loaderHandler(state) {
      state.isLoader = !state.isLoader;
    },
    newChatHandler(state) {
      state.messages.length > 0
        ? (state.newChat = true)
        : (state.newChat = false);
    },
    replaceChat(state, action) {
      state.messages = [];
      state.messages = action.payload.chats;
    },
    recentChatHandler(state, action) {
      state.recentChat = [...state.recentChat, ...action.payload.recentChat];
    },
    pushRecentChatHandler(state, action) {
      state.recentChat = [...action.payload.recentChat, ...state.recentChat];
    },
    initRecentChatHandler(state, action) {
      state.recentChat = action.payload.recentChat;
    },
    removeRecentChatHandler(state, action) {
      console.log(state.recentChat);
      state.recentChat = state.recentChat.filter(
        (f) => !action.payload.ids.includes(f._id)
      );
    },

    chatStart(state, action) {
      state.chats.push({
        user: action.payload.useInput.user,
        isLoader: action.payload.useInput.isLoader,
        gemini: action.payload.useInput.gemini,
        id: Math.random(),
        newChat: true,
      });
    },
    previousChatHandler(state, action) {
      state.previousChat.push(
        action.payload.previousChat[0],
        action.payload.previousChat[1]
      );
    },
    replacePreviousChat(state, action) {
      state.previousChat = action.payload.previousChat;
    },
    chatHistoryIdHandler(state, action) {
      state.chatHistoryId = action.payload.chatHistoryId;
    },
    suggestPromptHandler(state, action) {
      state.suggestPrompt = action.payload.prompt;
    },
    popChat(state) {
      state.messages.pop();
    },
    updateStreamMessages(state, action) {
      state.messages = [...state.messages, action.payload];
    },
    updateStreamMesssageByTempId(state, action) {
      state.messages = state.messages.map((message) => {
        if (message.tempId === action.payload.tempId) {
          message._id = action.payload._id;
          message.message.gemini = action.payload.message.gemini;
        }
        return message;
      });
    },
    setReleatedQuestions(state, action) {
      state.relatedQuestions = [...[tellMemore], ...action.payload];
    },
  },
});

export const chatAction = chatSlice.actions;

export default chatSlice.reducer;
