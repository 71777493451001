import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PlanCard = ({
  plan,
  userDetails,
  handleApprove,
  isRetrying,
  currentPlanId,
}) => {

  if (currentPlanId !== "free" && plan._id === "free") {
    return <></>;
  }

  return (
    <div className="bg-white mx-2 rounded-lg shadow-lg max-w-sm bg-gradient-to-b from-transparent via-[#d8eaf4]">
      <div className="text-sm relative gap-5 border border-slate-100 md:min-h-[30rem] md:w-80 justify-center px-6 pt-6 pb-10 md:pb-6">
        <div className="w-full">
          <h2 className="text-gray-900 text-4xl text-center font-bold mb-2">
            {plan.name}
          </h2>
        </div>
        <div className="text-black mb-2 p-4 flex flex-row items-baseline">
          <div className="font-[800] text-5xl">${plan.pricingScheme.price}</div>
          <div className="font-semibold text-gray-500">
            /{plan.frequency.intervalUnit.toLowerCase()}
          </div>
        </div>
        {currentPlanId === plan.planId || plan._id === "free" ? (
          <button
            className="bg-gray-200 text-gray-500 py-2 p-4 rounded mb-4"
            disabled
          >
            Your current plan
          </button>
        ) : (
          <div className="p-4">
            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                vault: "true",
              }}
            >
              <PayPalButtons
                createSubscription={(data, actions) => {
                  return actions.subscription.create({
                    plan_id: plan.planId,
                    custom_id: userDetails.id,
                    auto_renewal: true,
                    application_context: {
                      return_url: "http://localhost:3000/return",
                      cancel_url: "http://localhost:3000/cancel",
                    },
                  });
                }}
                onApprove={handleApprove}
                style={{
                  layout: "horizontal",
                  color: "blue",
                  shape: "pill",
                  label: "subscribe",
                }}
              />
              {isRetrying && <div>Retrying to save subscription data...</div>}
            </PayPalScriptProvider>
          </div>
        )}

        <div
          className="flex p-4 items-center [&_p]:py-1 [&_li]:py-1"
          dangerouslySetInnerHTML={{ __html: `${plan.description}` }}
        />
      </div>
    </div>
  );
};

export default PlanCard;
