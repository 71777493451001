import { createSlice } from "@reduxjs/toolkit";

const userInitialState = {
  user: {
    id: "",
    name: "User",
    biography: "",
    email: "",
    profileImg: "",
    phone: "",
    subscription: {},
  },
  location: "",
};

const userSclice = createSlice({
  name: "userSlice",
  initialState: userInitialState,
  reducers: {
    setLocation(state, action) {
      state.location = action.payload.location;
    },
    setUserData(state, action) {
      state.user = { ...state.user, ...action.payload.userData };
    },
    setUserAvatar(state, action) {
      state.user.profileImg = action.payload;
    },
  },
});

export const userAction = userSclice.actions;

export default userSclice.reducer;
