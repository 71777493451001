import React from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };
  const handleFirstPrevious = () => {
    if (currentPage > 1) {
      onPageChange(1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
    console.log(startPage, endPage, currentPage);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`px-3 py-1 rounded ${
            i === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex items-center justify-center p-4 space-x-2">
      <button
        className={`px-3 py-1 rounded bg-gray-200 text-gray-700 hover:bg-gray-300 cursor-pointer ${
          currentPage > 5 ? "visible" : "invisible"
        }`}
        onClick={handleFirstPrevious}
      >
        First
      </button>
      <button
        className="px-3 py-1 rounded bg-gray-200 text-gray-700 hover:bg-gray-300 cursor-pointer"
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {renderPageNumbers()}
      <button
        className="px-3 py-1 rounded bg-gray-200 text-gray-700 hover:bg-gray-300 cursor-pointer"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
