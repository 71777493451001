const Term = () => {
  return (
    <div className="w-full h-dvh bg-gray-50">
      <div className="flex flex-col items-center mx-auto w-3/5 justify-center">
        <div>
          <h2 className="font-semibold py-2">Terms of Service</h2>
        </div>
        <div className="text-left">
          <p>
            <br />
          </p>
          <p>
            Welcome to Gospel Truth AI. By using our chatbot services, you agree
            to the following Terms of Service. Please read them carefully.
          </p>
          <p>
            <br />
          </p>
          <h3 className="font-semibold py-2">1. Use of Services</h3>
          <p>
            Eligibility: You must be at least 18 years old to use our services.
          </p>
          <p>
            Acceptable Use: You agree to use Gospel Truth AI for lawful and
            non-commercial purposes, and in a manner consistent with the
            religious nature of the content.
          </p>
          <h3 className="font-semibold py-2">2. Payments and Refunds</h3>
          <p>
            Non-Refundable Payments: All payments made for services provided by
            Gospel Truth AI are non-refundable. This applies to all
            subscriptions, one-time payments, and any other fees.
          </p>
          <p>
            Payment Method: Payments are processed through third-party payment
            processors. You agree to provide accurate and complete payment
            information.
          </p>
          <h3 className="font-semibold py-2">3. Intellectual Property</h3>
          <p>
            Ownership: All content, including text, images, and software,
            provided by Gospel Truth AI is the intellectual property of Gospel
            Truth AI or its licensors.
          </p>
          <p>
            License: You are granted a limited, non-exclusive, and
            non-transferable license to use the services for personal,
            non-commercial purposes.
          </p>
          <h3 className="font-semibold py-2">4. Limitation of Liability</h3>
          <p>
            No Warranty: Gospel Truth AI is provided &quot;as is,&quot; without
            any warranties or guarantees.
          </p>
          <p>
            Limitation of Liability: We are not liable for any indirect,
            incidental, or consequential damages arising out of your use of our
            services.
          </p>
          <h3 className="font-semibold py-2">5. Termination</h3>
          <p>
            Right to Terminate: We reserve the right to terminate or suspend
            your access to our services at any time, with or without cause, and
            without notice.
          </p>
          <h3 className="font-semibold py-2">6. Governing Law</h3>
          <p>
            These Terms of Service are governed by the laws of [Your
            Jurisdiction]. Any disputes arising from these terms will be
            resolved in the courts of [Your Jurisdiction].
          </p>
          <p>
            <br />
          </p>
          <h3 className="font-semibold py-2">7. Changes to These Terms</h3>
          <p>
            We may update these Terms of Service from time to time. Any changes
            will be posted on this page with an updated revision date.
          </p>
          <p>
            <br />
          </p>
          <h3 className="font-semibold py-2">8. Contact Us</h3>
          <p>
            If you have any questions or concerns about these Terms of Service,
            please contact us at{" "}
            <strong>
              <a data-fr-linked="true" href="mailto:legal@gospeltruth.ai">
                legal@gospeltruth.ai
              </a>
            </strong>
            .
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Term;
