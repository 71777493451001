import styles from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { uiAction } from "../../store/ui-gemini";
import { themeIcon } from "../../asset";
import { useNavigate } from "react-router-dom";
import { chatAction } from "../../store/chat";
import { commonIcon } from "../../asset";
import BranchText from "../Ui/BranchText";

const Header = ({ fullLogo, hideLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNewChat = useSelector((state) => state.chat.newChat);
  const isUserDetails = useSelector((state) => state.ui.isUserDetailsShow);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const userDetails = useSelector((state) => state.user.user);

  const toggleSideBarHandler = () => {
    dispatch(uiAction.toggleSideBar());
  };

  const toggleAadvanceGeminiHandler = () => {
    dispatch(uiAction.toggleAdvanceShow());
  };

  const icon = themeIcon();

  const newChatHandler = () => {
    dispatch(chatAction.replaceChat({ chats: [] }));
    dispatch(chatAction.newChatHandler());
    dispatch(chatAction.chatHistoryIdHandler({ chatHistoryId: "" }));
    navigate("/");
  };

  const userDetailsOpen = () => {
    dispatch(uiAction.toggleUserDetailsShow());
  };

  const loginHandler = () => {
    navigate("/login");
    // window.open(continueWithGoogleOauth(), "_self");
  };

  const loginSection = () => {
    if (hideLogin) {
      return <></>;
    }

    if (isLogin) {
      return (
        <div
          onClick={userDetailsOpen}
          className={`${styles["user"]} ${
            isUserDetails ? styles["clicked-user"] : ""
          }`}
        >
          {userDetails?.profileImg?.length > 0 ? (
            <img src={userDetails?.profileImg} alt=""></img>
          ) : (
            <img src={commonIcon.defaultAvatar} alt=""></img>
          )}
        </div>
      );
    }

    return (
      <div className={styles["login"]} onClick={loginHandler}>
        <p>Log in</p>
      </div>
    );
  };

  return (
    <div className={styles["header-main"]}>
      <div className={styles["left-section"]}>
        {!fullLogo && (
          <div className={styles["menu-icon"]} onClick={toggleSideBarHandler}>
            <img src={commonIcon.menuIcon} alt="menu icon"></img>
          </div>
        )}
        {fullLogo ? (
          <div className={styles["name"]} onClick={() => navigate("/")}>
            <img
              src={commonIcon.fullLogo2}
              className="!w-40 !h-auto"
              alt="Gospel Truth AI"
              title="Home"
            ></img>
          </div>
        ) : (
          <div className={styles["name"]} onClick={toggleAadvanceGeminiHandler}>
            <BranchText />
            <img src={icon.dropIconSmall} alt="drop down button"></img>
          </div>
        )}
      </div>
      <div className={styles["right-section"]}>
        {isNewChat ? (
          <div
            onClick={newChatHandler}
            className={`${styles["plus-icon"]} ${styles["new-plus-icon"]}`}
          >
            <img
              src={icon.plusIcon}
              alt="plus icon"
              title="Click Here for New Chat"
            ></img>
          </div>
        ) : null}
      </div>
      {loginSection()}
    </div>
  );
};

export default Header;
