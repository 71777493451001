import { useEffect } from "react";
import Header from "../../components/Header/Header";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UserDetails from "../UserDetails/UserDetails";
import { uiAction } from "../../store/ui-gemini";

const SubscriptionLayout = ({hideLogin}) => {
  const isUserDetails = useSelector((state) => state.ui.isUserDetailsShow);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const isDark = useSelector((state) => state.ui.isDark);
  const dispatch = useDispatch();

  useEffect(() => {
    const getLocalTheme = localStorage.getItem("theme");
    const theme = getLocalTheme || "light";
    document.documentElement.setAttribute("data-theme", theme);
  }, [isDark]);

  return (
    <>
      <Header fullLogo={true} hideLogin={hideLogin}/>

      <Outlet />

      {isUserDetails && isLogin && <UserDetails />}

      {isUserDetails && isLogin && (
        <div
          onClick={() => dispatch(uiAction.toggleUserDetailsShow())}
          className="bg-focus-dark"
        ></div>
      )}
    </>
  );
};

export default SubscriptionLayout;
