/* eslint-disable no-unused-vars */
import React, { useState } from "react";

const ImageUpload = ({ fileInputRef, onChange }) => {
  const [image, setImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file || file.size <= 0) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);

    onChange(file);
  };

  return (
    <div>
      <input
        hidden
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        // accept="text/csv, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </div>
  );
};

export default ImageUpload;
