/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/SettingsPopup.jsx

import React, { useState } from "react";

import "./SettingSection.module.css";
import { useSelector, useDispatch } from "react-redux";
import { uiAction } from "../../store/ui-gemini";
import { IconProfile, IconContact, IconClose } from "../Ui/Icon";
import UserProfile from "../UserDetails/UserProfile";
import UserSubscription from "../UserDetails/UserSubscription";

const SettingSection = () => {
  const dispatch = useDispatch();
  const isSettingsShow = useSelector((state) => state.ui.isSettingsShow);
  const [activeTab, setActiveTab] = useState(1);

  const settingsHandler = (e) => {
    dispatch(uiAction.toggleSettings());
  };

  return (
    isSettingsShow && (
      <div
        aria-hidden="true"
        className="setting-modal flex overflow-y-auto bg-gray-700 bg-opacity-75 z-[6] overflow-x-hidden fixed justify-center items-center w-full inset-0 max-h-full"
        onClick={settingsHandler}
      >
        <div
          className="relative p-4 w-full max-w-4xl max-h-full"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative bg-white rounded-lg shadow  min-h-[500px]">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
              <h3 className="text-xl font-semibold text-gray-900">Settings</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                data-modal-hide="default-modal"
                onClick={settingsHandler}
              >
                <IconClose />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
              <div className="flex flex-col md:flex-row w-full gap-1">
                <div className="border-gray-200  w-32">
                  <ul className="flex md:flex-col flex-row text-sm font-medium text-left text-gray-500 ">
                    <li
                      className={`
                       ${
                         activeTab === 1
                           ? "text-blue-600 bg-[var(--menuicon-hover-bg)]"
                           : "border-transparent"
                       } me-2 w-full  border-b-2 rounded-md`}
                    >
                      <a
                        href="#"
                        onClick={() => setActiveTab(1)}
                        className={`inline-flex items-center justify-center p-3 hover:text-gray-600 hover:border-gray-300 group`}
                      >
                        <IconProfile
                          className={`w-4 h-4 me-2 group-hover:text-gray-500 active ${
                            activeTab === 1 ? "text-blue-600" : "text-gray-400"
                          }`}
                        />
                        Profile
                      </a>
                    </li>
                    <li
                      className={`
                       ${
                         activeTab === 2
                           ? "text-blue-600 bg-[var(--menuicon-hover-bg)]"
                           : "border-transparent"
                       } me-2 w-full  border-b-2 rounded-md`}
                    >
                      <a
                        href="#"
                        onClick={() => setActiveTab(2)}
                        className={`inline-flex items-center justify-center p-3 hover:text-gray-600 hover:border-gray-300 group`}
                      >
                        <IconContact
                          className={`w-4 h-4 me-2 group-hover:text-gray-500 active ${
                            activeTab === 2 ? "text-blue-600" : "text-gray-400"
                          }`}
                        />
                        Subscription
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full p-4 overflow-x-auto shadow-md shadow-slate-300">
                  {activeTab === 1 && <UserProfile />}
                  {activeTab === 2 && <UserSubscription />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SettingSection;
