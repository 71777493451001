/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import {
  adminGetUsers,
  adminActiveUsers,
  adminDeactiveUsers,
} from "../../../store/user-action";
import LoadingOverlay from "../../../components/Ui/LoadingOverlay";
import SearchInput from "../../../components/Ui/Search";
import Pagination from "../../../components/Ui/Paging";
import { calculatePage } from "../../../utils/paging";

import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDateToCustomString } from "../../../utils/utils";

const ManagerSubscription = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [paging, setPaging] = useState({
    page: parseInt(searchParams.get("page")) || 1,
    limit: parseInt(searchParams.get("limit")) || 10,
    sortBy: searchParams.get("sortBy") || "createdAt",
    sortType: searchParams.get("sortType") || "DESC",
    search: searchParams.get("search") || "",
    total: 0,
    totalPages: 0,
  });

  const setQueryParams = (queryParams) => {
    const searchParams = new URLSearchParams();

    Object.keys(queryParams).forEach((key) => {
      searchParams.set(key, queryParams[key]);
    });

    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const dataSubs = await adminGetUsers(paging);
      setTimeout(() => {
        setData(dataSubs.data);
        setPaging((prev) => ({
          ...prev,
          total: dataSubs.total,
          totalPages: calculatePage(dataSubs.total, prev.limit),
        }));
      });

      setLoading(false);
    };
    fetchData();
    setTimeout(() => {
      setQueryParams(paging);
    });
  }, [
    paging.page,
    paging.limit,
    paging.sortBy,
    paging.sortType,
    paging.search,
  ]);

  const onDeactiveUser = (id) => {
    const callAPI = async () => {
      setLoading(true);
      try {
        await adminDeactiveUsers(id);
        setTimeout(() => {
          setLoading(false);
        });
        setData((prev) => {
          return prev.map((tem) => {
            if (id === tem._id) {
              tem.status = "inactive";
            }
            return tem;
          });
        });

        toast.success("Successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callAPI();
  };

  const onActiveUser = (id) => {
    const callAPI = async () => {
      setLoading(true);
      try {
        await adminActiveUsers(id);
        setTimeout(() => {
          setLoading(false);
        });
        setData((prev) => {
          return prev.map((tem) => {
            if (id === tem._id) {
              tem.status = "active";
            }
            return tem;
          });
        });

        toast.success("Successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };

    callAPI();
  };
  const handlePageChange = (page) => {
    setPaging((prev) => ({
      ...prev,
      page,
    }));
  };

  const onSearch = (search) => {
    setPaging((prev) => ({
      ...prev,
      search,
    }));
  };

  const dataSection = useMemo(() => {
    const handleOnChangeStatus = (item) => {
      console.log(item.status);
      if (item.status === "inactive") {
        onActiveUser(item._id);
      } else {
        onDeactiveUser(item._id);
      }
    };

    return (
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              ID
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Phone
            </th>
            <th scope="col" className="px-6 py-3">
              isSubscribed
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Created At
            </th>
            {/* <th scope="col" className="px-6 py-3">
              <span className="sr-only">Edit</span>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index} className="bg-white border-b ">
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
              >
                {item._id}
              </th>
              <td className="px-6 py-4">{item?.email}</td>
              <td className="px-6 py-4">{item.phone}</td>
              <td className="px-6 py-4">
                <span
                  className={`${
                    item.subscription ? "  text-blue-700" : " text-red-700"
                  } inline-flex items-center text-xs font-medium px-2.5 py-0.5 rounded-full`}
                >
                  {item.subscription ? "True" : "False"}
                </span>
              </td>
              <td className="px-6 py-4">
                <label className="group inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={item.status === "active"}
                    onChange={(e) => handleOnChangeStatus(item)}
                  />
                  <span className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all peer-checked:bg-blue-600">
                    {item.status === "active" && (
                      <span className="opacity-0 transition-all duration-300 group-hover:opacity-80 group-hover:[translate:-100%] inline-flex items-center  text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full ">
                        {item.status}
                      </span>
                    )}
                    {item.status === "inactive" && (
                      <span className="opacity-0 transition-all duration-300 group-hover:opacity-80 group-hover:[translate:70%] inline-flex items-center  text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full ">
                        {item.status}
                      </span>
                    )}
                  </span>
                </label>
              </td>
              <td>{formatDateToCustomString(item.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [data]);

  const pagine = useMemo(() => {
    if (!paging.totalPages || paging.total <= paging.limit) return <></>;
    return (
      <Pagination
        totalPages={paging.totalPages}
        currentPage={paging.page}
        onPageChange={handlePageChange}
      />
    );
  }, [paging.page, paging.totalPages]);
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="flex w-full justify-between items-center p-3 flex-wrap md:flex-nowrap">
        <h2 className="font-semibold text-3xl text-left rtl:text-right text-gray-900">
          Users
        </h2>
        <SearchInput className="md:mr-28" onSubmitSearch={onSearch} />
      </div>
      {dataSection}
      {pagine}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default ManagerSubscription;
