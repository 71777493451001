/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { getUserSubscription } from "../../store/subscription-action";
import { formatDateToCustomString } from "../../utils/utils";
import LoadingOverlay from "../Ui/LoadingOverlay";

const UserSubscription = () => {
  const [subs, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const dataSubs = await getUserSubscription();
      setTimeout(() => {
        setData(dataSubs.data);
        setLoading(false);
      });
    };
    fetchData();
  }, []);

  const dataSection = useMemo(() => {
    return (
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white">
          Subscription
          <p className="mt-1 text-sm font-normal text-gray-500 "></p>
        </caption>
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="px-4 py-3">
              ID
            </th>
            <th scope="col" className="px-4 py-3">
              Package
            </th>
            <th scope="col" className="px-4 py-3">
              Start
            </th>
            <th scope="col" className="px-4 py-3">
              Next Billing Time
            </th>
            <th scope="col" className="px-4 py-3">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            subs?.map((item, index) => (
              <tr key={index} className="bg-white border-b last:border-none">
                <th scope="row" className="px-4 py-4 whitespace-nowrap ">
                  {item.paypalSubscriptionId}
                </th>
                <td className="px-4 py-4 font-medium text-gray-900 ">
                  {item.plan?.name}
                </td>
                <td className="px-4 py-4">
                  {formatDateToCustomString(item.startTime)}
                </td>
                <td className="px-4 py-4">
                  {formatDateToCustomString(item.nextBillingTime)}
                </td>
                <td className="px-4 py-4">
                  <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                    {item.status}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <LoadingOverlay />
          )}
        </tbody>
      </table>
    );
  }, [subs, loading]);

  return <div className="relative">{dataSection}</div>;
};

export default UserSubscription;
