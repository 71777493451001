import { chatAction } from "./chat";
import axios from "axios";
import { toast } from "react-toastify";
import emitter from "../utils/events";

let abortController = new AbortController();
emitter.addListener("abort-chat", function (x, y) {
  abortController.abort("Stop");
  abortController = new AbortController();
});

export const getRecentChat = (paging) => {
  return (dispatch) => {
    if (!paging) {
      paging = {
        limit: 1,
      };
    }
    const url = axios.getUri({ url: "/api/getchathistory", params: paging });
    axios(url, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.data && data.data.length) {
          const chat = data.data[0];
          dispatch(
            chatAction.pushRecentChatHandler({
              recentChat: [data.data[0]],
            })
          );

          dispatch(
            chatAction.chatHistoryIdHandler({ chatHistoryId: chat._id })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getChat = (chatHistoryId) => {
  return (dispatch) => {
    dispatch(chatAction.loaderHandler());
    const url = `/api/chatdata`;
    axios(url, {
      method: "POST",
      data: JSON.stringify({ chatHistoryId }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        const previousChat = data.chats.flatMap((c) => [
          { role: "user", parts: c.message.user },
          { role: "model", parts: c.message.gemini },
        ]);

        const chatHistoryId = data.chatHistory;

        dispatch(chatAction.replacePreviousChat({ previousChat }));
        dispatch(chatAction.replaceChat({ chats: data.chats }));
        dispatch(chatAction.chatHistoryIdHandler({ chatHistoryId }));
        dispatch(chatAction.newChatHandler());
        dispatch(chatAction.loaderHandler());
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          chatAction.replaceChat({
            chats: [],
          })
        );
        dispatch(chatAction.loaderHandler());
        dispatch(chatAction.chatHistoryIdHandler({ chatHistoryId }));
      });
  };
};

export const sendChatData = (useInput) => {
  return async (dispatch) => {
    dispatch(
      chatAction.updateStreamMessages({
        message: {
          user: useInput.user,
          gemini: "",
        },
        _id: Date.now().toString(),
        timestamp: Date.now(),
        newChat: true,
      })
    );

    dispatch(chatAction.loaderHandler());
    dispatch(chatAction.setIsReplying(true));

    const apiKey = process.env.REACT_APP_GEMINI_KEY;
    const endpoint = process.env.REACT_APP_SERVER_ENDPOINT.includes("http")
      ? process.env.REACT_APP_SERVER_ENDPOINT
      : "";
    const url = `${endpoint}/api/chat`;

    try {
      const response = await axios(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": apiKey,
        },
        signal: abortController.signal,
        data: JSON.stringify({
          chatType: useInput.chatType,
          userInput: useInput.user,
          previousChat: useInput.previousChat,
          chatHistoryId: useInput.chatHistoryId,
        }),
      });
      const data = response.data?.data;
      const questions = response.data?.relatedQuestions;

      dispatch(chatAction.popChat());
      dispatch(
        chatAction.updateStreamMessages({
          ...data,
          newChat: true,
        })
      );
      dispatch(chatAction.loaderHandler());
      dispatch(chatAction.newChatHandler());
      dispatch(
        chatAction.previousChatHandler({
          previousChat: [
            { role: "user", parts: useInput.user },
            { role: "model", parts: data.message?.gemini || "" },
          ],
        })
      );
      if (!useInput.chatHistoryId) {
        dispatch(getRecentChat());
      }
      dispatch(chatAction.setMessageLength(data.message?.gemini?.length || 0));
      if (questions && questions.length) {
        dispatch(chatAction.setReleatedQuestions(questions));
      }

      return [];
    } catch (error) {
      if (error.response?.status === 401) {
        window.location.href = "/login";
        return;
      }

      dispatch(chatAction.newChatHandler());
      dispatch(chatAction.loaderHandler());
      dispatch(chatAction.setIsReplying(false));
      dispatch(chatAction.popChat());
      if (axios.isCancel(error)) {
        dispatch(
          chatAction.updateStreamMessages({
            message: {
              user: useInput.user,
              gemini: "Request Cancelled.",
            },
            _id: Date.now(),
            timestamp: Date.now(),
          })
        );
        return;
      }
      if (error.response?.status === 429) {
        dispatch(
          chatAction.updateStreamMessages({
            message: {
              user: useInput.user,
              gemini:
                "Rate Limit Exceeded. Please upgrade Gospel Truth Pro to continue. Thank you for your patience.",
            },
            _id: Date.now(),
            timestamp: Date.now(),
          })
        );
      } else {
        dispatch(
          chatAction.updateStreamMessages({
            message: {
              user: useInput.user,
              gemini:
                "Oops! Something went wrong on our end. Please refresh the page and try again. If the issue persists, please contact us for assistance.",
            },
            _id: Date.now(),
            timestamp: Date.now(),
          })
        );
      }
    }
  };
};

export const deleteRecentChat = (id) => {
  return (dispatch) => {
    const url = `/api/recent/${id}`;

    axios(url, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(
          chatAction.removeRecentChatHandler({
            ids: [id],
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteRecentChats = async (ids) => {
  const url = `/api/recents`;

  try {
    const response = await axios(url, {
      method: "DELETE",
      credentials: "include",
      data: { ids },
    });
    return response.data;
  } catch (error) {
    toast.error(
      "Oops! Something went wrong on our end. Please refresh the page and try again."
    );
  }
};

export const getRecentPaging = async (query) => {
  const url = axios.getUri({ url: "/api/getchathistory", params: query });
  try {
    const response = await axios(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed:", error);
    return false;
  }
};

export const sendChatDataStreamResponse = (useInput) => {
  return async (dispatch) => {
    const tempId = Math.random();
    const tempStartChat = {
      newChat: true,
      tempId,
      message: {
        user: useInput.user,
        gemini: "",
      },
      _id: Date.now().toString(),
      timestamp: Date.now(),
    };
    dispatch(chatAction.updateStreamMessages(tempStartChat));
    dispatch(chatAction.loaderHandler());
    dispatch(chatAction.setIsReplying(true));
    const apiKey = process.env.REACT_APP_GEMINI_KEY;
    const endpoint = process.env.REACT_APP_SERVER_ENDPOINT.includes("http")
      ? process.env.REACT_APP_SERVER_ENDPOINT
      : "";
    const url = `${endpoint}/api/chat`;

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": apiKey,
        },
        body: JSON.stringify({
          userInput: useInput.user,
          previousChat: useInput.previousChat,
          chatHistoryId: useInput.chatHistoryId,
        }),
        responseType: "stream",
      });
      if (response.status === 401) {
        window.location.href = "/login";
        return;
      }
      dispatch(chatAction.popChat());
      if (response.status === 429) {
        dispatch(
          chatAction.updateStreamMessages({
            message: {
              user: useInput.user,
              gemini:
                "Rate Limit Exceeded. Please upgrade Gospel Truth Pro to continue. Thank you for your patience.",
            },
            _id: Date.now(),
            timestamp: Date.now(),
          })
        );
        dispatch(chatAction.newChatHandler());
        dispatch(chatAction.loaderHandler());
        dispatch(chatAction.setIsReplying(false));
        return;
      }
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const reader = response.body.getReader();
      let result = "";
      const decoder = new TextDecoder();
      let isFirst = true;

      const processStream = async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }

          const chunkValue = decoder.decode(value);
          result += chunkValue;

          if (isFirst) {
            isFirst = false;
            dispatch(
              chatAction.updateStreamMesssageByTempId({
                ...tempStartChat,
                newChat: true,
                message: {
                  user: useInput.user,
                  gemini: chunkValue,
                },
              })
            );
          } else {
            dispatch(
              chatAction.updateStreamMesssageByTempId({
                tempStartChat,
                message: {
                  user: useInput.user,
                  gemini: result,
                },
              })
            );
          }
        }
      };
      await processStream();
      dispatch(chatAction.loaderHandler());
      dispatch(chatAction.newChatHandler());
      dispatch(
        chatAction.previousChatHandler({
          previousChat: [
            { role: "user", parts: useInput.user },
            { role: "model", parts: result },
          ],
        })
      );
      if (!useInput.chatHistoryId) {
        dispatch(getRecentChat());
      }
      dispatch(chatAction.setMessageLength(result.length));
      return result;
    } catch (error) {
      dispatch(chatAction.popChat());
      dispatch(chatAction.newChatHandler());
      dispatch(chatAction.loaderHandler());
      dispatch(chatAction.setIsReplying(false));

      dispatch(
        chatAction.updateStreamMessages({
          message: {
            user: useInput.user,
            gemini:
              "Oops! Something went wrong on our end. Please refresh the page and try again. If the issue persists, please contact us for assistance.",
          },
          _id: Date.now(),
          timestamp: Date.now(),
        })
      );
    }
  };
};

export const getShareMessage = async (id) => {
  const url = `/api/share/${id}`;
  return axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
