/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import PlanCard from "../../components/Ui/PlanCard";
import React, { useMemo, useEffect, useState } from "react";
import {
  getPlans,
  saveSubscriptionData,
} from "../../store/subscription-action";
import { getMe } from "../../store/auth-action";
import LoadingOverlay from "../../components/Ui/LoadingOverlay";
import { useSelector, useDispatch } from "react-redux";
import { uiAction } from "../../store/ui-gemini";

const Subscription = () => {
  const userDetails = useSelector((state) => state.user.user);
  const [isRetrying, setIsRetrying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();
  const PLAN_STATUS = {
    INACTIVE: "INACTIVE",
    ACTIVE: "ACTIVE",
  };
  const Free = {
    _id: "free",
    name: "FREE",
    status: "ACTIVE",
    description:
      "<ul><li>✔️ Limit 10 requests per day</li><li>✔️ Limited access to advanced data analysis, vision, web browsing</li></ul>",
    usageType: "TRIAL",
    pricingScheme: {
      price: 0,
      currencyCode: "USD",
    },
    sequence: 0,
    totalCycles: 1,
    frequency: {
      intervalUnit: "MONTH",
      intervalCount: 1,
    },
    limitRequest: {
      count: 10,
      unit: "MONTH",
    },
  };

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      const data = await getPlans({
        sortBy: "order",
        sortType: "ASC",
        status: PLAN_STATUS.ACTIVE,
      });
      setPlans([Free, ...data?.data]);
      setLoading(false);
    };
    fetchPlans();
  }, []);

  const retryPendingSubscription = async () => {
    const pendingSubscription = localStorage.getItem("pendingSubscription");

    if (pendingSubscription) {
      const subscriptionData = JSON.parse(pendingSubscription);
      const success = await saveSubscriptionData(subscriptionData);

      if (success) {
        localStorage.removeItem("pendingSubscription");
        setIsRetrying(false);
        alert("Pending subscription data saved successfully.");
      }
    }
  };
  const handleBack = () => {
    // Implement back button functionality here
    // For example, you can use history.back() to go to the previous page
    dispatch(uiAction.toggleAdvanceShow());
    window.history.back();
  };

  // Retry pending subscription when internet connection is restored
  window.addEventListener("online", retryPendingSubscription);

  const currentPlanId = (planId) => {
    if (!userDetails.subscription) {
      return Free._id;
    }
    return userDetails.subscription.planId;
  };
  const handleApprove = async (data, actions) => {
    console.log("Subscription approved", data, actions);

    const subscriptionData = {
      subscriptionId: data.subscriptionID,
    };

    const success = await saveSubscriptionData(subscriptionData);

    if (!success) {
      // Store subscription data in local storage for later retry
      localStorage.setItem(
        "pendingSubscription",
        JSON.stringify(subscriptionData)
      );
      setIsRetrying(true);
      alert(
        "Subscription succeeded but failed to save data. Will retry when internet is available."
      );
    } else {
      dispatch(getMe());
    }
  };
  const planFrag = useMemo(() => {
    return (
      <div className="flex flex-col justify-center gap-4 px-3 py-3 md:min-h-[30rem] md:flex-row md:gap-0 md:py-0">
        {plans.map((plan, index) => (
          <PlanCard
            key={index}
            plan={plan}
            userDetails={userDetails}
            handleApprove={handleApprove}
            isRetrying={isRetrying}
            currentPlanId={currentPlanId()}
          />
        ))}
      </div>
    );
  }, [userDetails, isRetrying, currentPlanId, plans, handleApprove]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex w-full min-h-24 my-5 justify-center items-center">
        <h1 className="text-3xl font-bold text-gray-700">Upgrade your plan</h1>
      </div>
      {planFrag}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default Subscription;
