import React, { useState } from "react";
import { IconClose } from "../../../components/Ui/Icon";
import LoadingOverlay from "../../../components/Ui/LoadingOverlay";
import { adminCreatePlan } from "../../../store/subscription-action";
import { toast } from "react-toastify";

import TextEditor from "../../../components/Ui/SunEditor";

const CreatePlan = ({ togglePopup, onSubmited }) => {
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    order: 1,
    title: "",
    description: "",
    pricingScheme: {
      currencyCode: "USD",
      price: 0,
    },
    frequency: {
      intervalUnit: "MONTH",
    },
    limitRequest: {
      unit: "MONTH",
      count: 0,
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValue((preValue) => ({
      ...preValue,
      [`${name}`]: value,
    }));
  };

  const handleDesContentChange = (content) => {
    setFormValue((preValue) => ({
      ...preValue,
      description: content,
    }));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const callAPI = async () => {
      console.log(formValue);
      setLoading(true);
      try {
        await adminCreatePlan(formValue);
        setTimeout(() => {
          setLoading(false);
        });

        toast.success("Successfully");
        togglePopup();
        onSubmited();
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callAPI();
  };

  return (
    <div>
      {loading && <LoadingOverlay />}
      <div
        onClick={togglePopup}
        className="modal flex overflow-y-auto overflow-x-hidden fixed inset-0 bg-gray-700 bg-opacity-75 z-[6] justify-center items-center w-full max-h-full"
      >
        <div
          className="relative p-4 w-full max-w-4xl max-h-full"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-black dark:text-white">
                Create Plan
              </h3>
              <button
                type="button"
                className="z-[7] text-gray-400 bg-transparent hover:bg-gray-200 hover:text-black rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={togglePopup}
              >
                <IconClose />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4">
              <div className="flex flex-col md:flex-row w-full">
                <div className="w-full p-4 overflow-x-auto shadow-md shadow-slate-300">
                  <form
                    onSubmit={(e) => onSubmitHandler(e)}
                    className="grid gap-4 sm:grid-cols-2 sm:gap-6 [&_input]:outline-none [&_textarea]:outline-none"
                  >
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formValue.name}
                        onChange={handleChange}
                        placeholder="FREE | SILVER | GOLD"
                        required
                        className="shadow-sm bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="title"
                        className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                      >
                        Short Description
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={formValue.title}
                        onChange={handleChange}
                        placeholder="Paypal plan short description"
                        required
                        className="shadow-sm bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                      >
                        Display Description
                      </label>
                      <TextEditor
                        data={formValue.description}
                        className="content"
                        customMin="customHeight"
                        handleChange={handleDesContentChange}
                        type="simple"
                      />
                    </div>

                    <div className="w-full md:w-auto">
                      <label
                        htmlFor="pricingScheme.currencyCode"
                        className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                      >
                        Currency Code
                      </label>
                      <input
                        type="text"
                        name="pricingScheme.currencyCode"
                        defaultValue={formValue.pricingScheme.currencyCode}
                        onChange={handleChange}
                        disabled
                        placeholder="USD"
                        className="cursor-not-allowed shadow-sm bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="w-full md:w-auto">
                      <label
                        htmlFor="pricingScheme.price"
                        className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                      >
                        Price
                      </label>
                      <input
                        type="number"
                        name="pricingScheme.price"
                        defaultValue={formValue.pricingScheme.price}
                        onChange={(e) =>
                          setFormValue((preValue) => ({
                            ...preValue,
                            pricingScheme: {
                              ...preValue.pricingScheme,
                              price: parseFloat(e.target.value),
                            },
                          }))
                        }
                        required
                        className="shadow-sm bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>

                    {/* <div className="w-full md:w-auto">
                      <label
                        htmlFor="frequency.intervalUnit"
                        className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                      >
                        Frequency Unit
                      </label>
                      <input
                        type="text"
                        name="frequency.intervalUnit"
                        defaultValue={formValue.frequency.intervalUnit}
                        onChange={handleChange}
                        placeholder="MONTH"
                        disabled
                        className="cursor-not-allowed shadow-sm bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="w-full md:w-auto">
                      <label
                        htmlFor="frequency.intervalCount"
                        className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                      >
                        Frequency Count
                      </label>
                      <input
                        type="number"
                        name="frequency.intervalCount"
                        defaultValue={formValue.frequency.intervalCount}
                        onChange={(e) =>
                          setFormValue((preValue) => ({
                            ...preValue,
                            frequency: {
                              ...preValue.frequency,
                              intervalCount: parseFloat(e.target.value),
                            },
                          }))
                        }
                        required
                        className="shadow-sm bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div> */}

                    <div className="flex flex-wrap md:flex-nowrap space-y-2 md:space-y-0 md:space-x-4">
                      <div className="w-full md:w-auto">
                        <label
                          htmlFor="limitRequest.count"
                          className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                        >
                          Limit Request / Month
                        </label>
                        <input
                          type="number"
                          name="limitRequest.count"
                          defaultValue={formValue.limitRequest.count}
                          onChange={(e) =>
                            setFormValue((preValue) => ({
                              ...preValue,
                              limitRequest: {
                                ...preValue.limitRequest,
                                count: parseFloat(e.target.value),
                              },
                            }))
                          }
                          required
                          className="shadow-sm bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap md:flex-nowrap space-y-2 md:space-y-0 md:space-x-4">
                      <div className="w-full md:w-auto">
                        <label
                          htmlFor="order"
                          className="block mb-2 text-sm font-medium text-black dark:text-white text-start"
                        >
                          Order
                        </label>
                        <input
                          type="order"
                          name="order"
                          defaultValue={formValue.order}
                          onChange={handleChange}
                          required
                          className="shadow-sm bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center sm:col-span-2">
                      <button
                        disabled={loading}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePlan;
