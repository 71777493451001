import styles from "./CopyBtn.module.css";
import { Fragment, useState } from "react";
import { IconShare } from "./Icon";
import SharePopup from "./Share";

const ShareBtn = ({ messageId, historyId, name ,content}) => {
  const [toggleShare, setToggleShare] = useState(false);

  return (
    <Fragment>
      <div
        onClick={(e) => setToggleShare(true)}
        className={`${styles["copy-icon"]} !right-10 ${styles["share-icon-one"]}`}
      >
        <IconShare />
      </div>

      {toggleShare && (
        <SharePopup
          name={name}
          content={content}
          messageId={messageId}
          togglePopup={(e) => setToggleShare(false)}
        />
      )}
    </Fragment>
  );
};

export default ShareBtn;
