import React, { memo } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const editorType = {
  simple: [
    ["undo", "redo"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor", "textStyle"],
    ["removeFormat"],
    ["preview"],
    ["outdent", "indent"],
    [("fullScreen", "showBlocks", "codeView")],
  ],
  basic: [
    ["undo", "redo"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor", "textStyle"],
    ["removeFormat"],
    ["preview", "print"],
    ["outdent", "indent"],
    [("fullScreen", "showBlocks", "codeView")],
  ],
  full: [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["paragraphStyle", "blockquote"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor", "textStyle"],
    ["removeFormat"],
    ["outdent", "indent"],
    ["align", "horizontalRule", "list", "lineHeight"],
    ["table", "link", "image", "video", "audio"],
    ["fullScreen", "showBlocks", "codeView"],
    ["preview", "print"],
    ["save", "template"],
  ],
};

const TextEditor = ({
  data = "",
  handleChange,
  type,
  customMin,
  customMax,
}) => {
  const basicMinHeight = {
    minHeight: "40vh",
  };
  const basicMaxHeight = {
    maxHeight: "40vh",
  };
  const customMinHeight = {
    minHeight: "10vh",
  };
  const customMaxHeight = {
    maxHeight: "10vh",
  };
  return (
    <SunEditor
      autoFocus={false}
      lang="en"
      setOptions={{
        showPathLabel: false,
        placeholder: "Enter your text here!!!",
        buttonList: type ? editorType[type] : editorType.basic,
        minHeight:
          customMin === "customHeight"
            ? customMinHeight.minHeight
            : basicMinHeight.minHeight,
        maxHeight:
          customMax === "customHeight"
            ? customMaxHeight.maxHeight
            : basicMaxHeight.maxHeight,
      }}
      defaultValue={data}
      onChange={handleChange}
    />
  );
};

export default memo(TextEditor);
