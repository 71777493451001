import { useState } from "react";

const SearchInput = ({ className, onSubmitSearch }) => {
  const [input, setInput] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    onSubmitSearch(input);
  };

  const handleOnChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className={`max-w-lg w-96 ${className}`}>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="relative w-full">
          <input
            type="search"
            id="search-dropdown"
            onChange={(e) => handleOnChange(e)}
            className="block outline-none p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search..."
          />
          <button
            type="submit"
            className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchInput;
