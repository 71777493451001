/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";

const DropdownRecentChat = ({
  chatId,
  handleDeleteRecentChat,
  setSelectMultiple,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleSelectMulti = (e) => {
    e.stopPropagation();
    setSelectMultiple(true);
    setIsOpen(false);
  };
  const handleDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    handleDeleteRecentChat(id);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex items-center w-full" ref={dropdownRef}>
      {/* <img
        className="!w-6 !h-6 right-0 p-1 z-10 opacity-30 hover:opacity-100 cursor-pointer absolute rotate-90"
        src={commonIcon.threedottIcon}
        // onClick={onClick}
        onClick={handleToggle}
        alt="message"
      ></img> */}
      <button
        onClick={(e) => handleToggle(e)}
        className="right-0 z-[5] absolute"
      >
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 4 15"
          className="w-6 h-6  p-1 right-0 opacity-30 hover:opacity-100 cursor-pointer"
        >
          <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
        </svg>
      </button>

      {isOpen && (
        <div className="origin-top-right z-20 absolute right-0 top-3 mt-2 w-48 divide-y divide-zinc-100 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <button
            className="w-full block px-4 py-3 text-sm text-zinc-700 hover:bg-zinc-100"
            role="menuitem"
            onClick={(e) => handleSelectMulti(e)}
          >
            Select
          </button>
          <button
            className="w-full block px-4 py-3 text-sm text-zinc-700 hover:bg-zinc-100"
            role="menuitem"
            onClick={(e) => handleDelete(e, chatId)}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default DropdownRecentChat;
