import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import uiReducer from "./ui-gemini";
import chatReducer from "./chat";
import userReducer from "./user";
import authReducer from "./auth";

const appReducer = combineReducers({
  ui: uiReducer,
  chat: chatReducer,
  user: userReducer,
  auth: authReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
