import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import InputSection from "../InputSection/InputSection";

import ChatType from "./ChatType";
import styles from "./ChatSection.module.css";
import { useSelector } from "react-redux";
import Loader from "../Ui/Loader";

const ChatSection = () => {
  const isLoader = useSelector((state) => state.chat.isLoader);
  return (
    <div className={styles["chat-section-main"]}>
      <Header />
      <ChatType />
      {isLoader && <Loader />}
      <Outlet />

      <InputSection />
      <div className={styles["warning-text"]}>
        <p>Computers make mistakes too, so check important information.</p>
      </div>
    </div>
  );
};

export default ChatSection;
