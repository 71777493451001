import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMe } from "../../store/auth-action";
// import { refreshToken } from "../../store/auth-action";

export function AuthMiddleware({ component, ...rest }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  // useEffect(() => {
  //   const refreshTokenHandler = setInterval(() => {
  //     const isLoginLocal = localStorage.getItem("isLogin");
  //     if (isLoginLocal) {
  //       dispatch(refreshToken());
  //     }
  //   }, 25 * 60 * 1000);

  //   return () => clearInterval(refreshTokenHandler);
  // }, [dispatch]);

  return component;
}
