/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import {
  adminGetPlans,
  adminUpdatePlanStatus,
} from "../../../store/subscription-action";
import LoadingOverlay from "../../../components/Ui/LoadingOverlay";
import UpdatePlan from "./Update";
import CreatePlan from "./Create";
import { IconPlus } from "../../../components/Ui/Icon";
import { toast } from "react-toastify";

const ManagerPlan = () => {
  const PLAN_STATUS = {
    INACTIVE: "INACTIVE",
    ACTIVE: "ACTIVE",
  };

  const [plans, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [selectValue, setSelectValue] = useState({});
  const fetchData = async () => {
    setLoading(true);
    const dataSubs = await adminGetPlans();
    setTimeout(() => {
      setData(dataSubs.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleUpdate = (item) => {
    setSelectValue(item);

    setIsPopupOpen(!isPopupOpen);
  };

  const updateStatus = (id, status) => {
    const callAPI = async () => {
      setLoading(true);
      try {
        await adminUpdatePlanStatus(id, status);
        setTimeout(() => {
          setLoading(false);
        });
        setData((prev) => {
          return prev.map((tem) => {
            if (id === tem._id) {
              tem.status = status;
            }
            return tem;
          });
        });

        toast.success("Successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };

    callAPI();
  };
  const dataSection = useMemo(() => {
    const handleOnChangeStatus = (item) => {
      console.log(item.status);
      if (item.status === PLAN_STATUS.ACTIVE) {
        updateStatus(item._id, PLAN_STATUS.INACTIVE);
      } else {
        updateStatus(item._id, PLAN_STATUS.ACTIVE);
      }
    };

    return (
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="px-5 py-2">
              ID
            </th>
            <th scope="col" className="px-5 py-2">
              Name
            </th>
            <th scope="col" className="px-5 py-2">
              Unit
            </th>
            <th scope="col" className="px-5 py-2">
              Amount
            </th>
            <th scope="col" className="px-5 py-2">
              Request/Month
            </th>
            <th scope="col" className="px-5 py-2">
              Status
            </th>
            <th scope="col" className="px-5 py-2">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {plans?.map((item, index) => (
            <tr key={index} className="bg-white border-b">
              <th
                scope="row"
                className="px-5 py-3 font-medium text-gray-900 whitespace-nowrap"
              >
                {item.planId}
              </th>
              <td className="px-5 py-3">{item.name}</td>
              <td className="px-5 py-3">
                {item.frequency?.intervalCount} {item.frequency?.intervalUnit}
              </td>
              <td className="px-5 py-3 flex justify-start items-center">
                {item.pricingScheme?.price} {item.pricingScheme?.currencyCode}
              </td>
              <td className="px-5 py-3">
                <span>{item.limitRequest?.count}</span>
              </td>
              <td className="px-5 py-3">
                <label className="group inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={item.status === PLAN_STATUS.ACTIVE}
                    onChange={(e) => handleOnChangeStatus(item)}
                  />
                  <span className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all peer-checked:bg-blue-600">
                    {item.status === PLAN_STATUS.ACTIVE && (
                      <span className="opacity-0 transition-all duration-300 group-hover:opacity-80 group-hover:[translate:-100%] inline-flex items-center  text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                        {item.status}
                      </span>
                    )}
                    {item.status === PLAN_STATUS.INACTIVE && (
                      <span className="opacity-0 transition-all duration-300 group-hover:opacity-80 group-hover:[translate:70%] inline-flex items-center  text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                        {item.status}
                      </span>
                    )}
                  </span>
                </label>
              </td>
              <td className="px-5 py-3 text-center">
                <a
                  href="#update"
                  onClick={(e) => toggleUpdate(item)}
                  className="font-medium text-blue-600 hover:underline"
                >
                  Edit
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [plans]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="flex w-full justify-between items-center p-3 flex-wrap md:flex-nowrap">
        <h2 className="font-semibold text-3xl text-left rtl:text-right text-gray-900">
          Plans
        </h2>
        <a
          href="#create"
          onClick={(e) => setIsOpenCreate(true)}
          type="submit"
          className="flex items-center p-2 text-sm font-medium h-full text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
        >
          <IconPlus />
          <span> Create</span>
        </a>
      </div>
      {dataSection}
      {isPopupOpen && (
        <UpdatePlan
          data={selectValue}
          onSubmited={fetchData}
          togglePopup={() => setIsPopupOpen(false)}
        />
      )}
      {isOpenCreate && (
        <CreatePlan
          onSubmited={fetchData}
          togglePopup={() => setIsOpenCreate(false)}
        />
      )}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default ManagerPlan;
