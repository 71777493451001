const ConfirmDeletePopup = ({ handleDelete, isOpen, togglePopup }) => {
  const handleDeleteClick = () => {
    handleDelete();
    togglePopup();
  };
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black opacity-50 absolute inset-0"
        onClick={togglePopup}
      ></div>
      <div className="bg-white p-6 rounded shadow-lg z-10">
        <h2 className="text-lg font-semibold mb-4">Confirm Delete</h2>
        <p className="mb-4">Are you sure you want to delete all this item?</p>
        <div className="flex justify-end space-x-2">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={handleDeleteClick}
          >
            Delete
          </button>
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
            onClick={togglePopup}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeletePopup;
