import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminMainSection = () => {
  const isSidebarLong = useSelector((state) => state.ui.isAdminSidebarLong);

  const mg = isSidebarLong ? "md:ml-52" : "ml-20";

  return (
    <div
      className={`${mg} relative overflow-y-auto overflow-x-hidden w-full min-h-[100dvh]`}
    >
      <Outlet />
      {/* <div className={styles["warning-text"]}>
        <p>Xbot Dashboard</p>
      </div> */}
    </div>
  );
};

export default AdminMainSection;
