const Policy = () => {
  return (
    <div className="w-full h-dvh bg-gray-50">
      <div className="flex flex-col items-center mx-auto w-3/5 justify-center">
        <div>
          <h2 className="font-semibold py-2">Privacy Policy</h2>
        </div>
        <div className="text-left">
          <p>
            <br />
          </p>
          <p>
            Gospel Truth AI (&ldquo;we,&rdquo; &ldquo;us,&rdquo; or
            &ldquo;our&rdquo;) respects your privacy and is committed to
            protecting the personal information you share with us. This Privacy
            Policy explains how we collect, use, and share your information when
            you use our religious-based chatbot services.
          </p>
          <p>
            <br />
          </p>
          <h3 className="font-semibold py-2">1. Information We Collect</h3>
          <p>
            Personal Information: We may collect personal information, such as
            your name, email address, and any other information you provide when
            you use our services.
          </p>
          <p>
            Usage Data: We automatically collect information about your
            interaction with Gospel Truth AI, including but not limited to IP
            address, browser type, device information, and usage patterns.
          </p>
          <h3 className="font-semibold py-2">2. How We Use Your Information</h3>
          <p>
            Service Provision: To provide, maintain, and improve our chatbot
            services.
          </p>
          <p>
            Communication: To communicate with you about updates, promotions, or
            other information related to our services.
          </p>
          <p>
            Legal Obligations: To comply with legal obligations and protect our
            rights.
          </p>
          <h3 className="font-semibold py-2">3. Sharing Your Information</h3>
          <p>
            Third-Party Service Providers: We may share your information with
            third-party service providers who assist us in delivering our
            services.
          </p>
          <p>
            Legal Compliance: We may disclose your information to comply with
            legal obligations or respond to lawful requests from public
            authorities.
          </p>
          <h3 className="font-semibold py-2">
            4. Security of Your Information
          </h3>
          <p>
            We take reasonable measures to protect your information from
            unauthorized access, disclosure, or destruction. However, no method
            of transmission over the Internet is 100% secure, and we cannot
            guarantee absolute security.
          </p>
          <p>
            <br />
          </p>
          <h3 className="font-semibold py-2">5. Your Rights</h3>
          <p>
            Access and Correction: You have the right to access and correct your
            personal information.
          </p>
          <p>
            Opt-Out: You can opt-out of receiving communications from us at any
            time by following the instructions in our emails.
          </p>
          <p>
            Data Deletion: You can request the deletion of your personal
            information, subject to our legal obligations.
          </p>
          <h3 className="font-semibold py-2">
            6. Changes to This Privacy Policy
          </h3>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated revision date.
          </p>
          <p>
            <br />
          </p>
          <h3 className="font-semibold py-2">7. Contact Us</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{" "}
            <strong>
              <a data-fr-linked="true" href="mailto:legal@gospeltruth.ai">
                legal@gospeltruth.ai
              </a>
            </strong>
            .
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policy;
