import axios from "axios";
import { toast } from "react-toastify";

export const uploads = async (formData) => {
  try {
    const url = `/api/uploads`;
    const response = await axios.post(url, formData, {
      credentials: "include",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    throw error;
  }
};
