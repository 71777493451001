import styles from "./CopyBtn.module.css";
import React, { useState } from "react";
import { IconClose } from "./Icon";
import { commonIcon } from "../../asset";
import LoadingOverlay from "./LoadingOverlay";
import { IconFacebook, IconX, IconReddit, IconLinkedln } from "./Icon";
import { themeIcon } from "../../asset";

const SharePopup = ({
  togglePopup,
  historyId,
  messageId,
  onSubmited,
  name,
  content,
}) => {
  const [loading, setLoading] = useState(false);

  const [copied, setCopied] = useState(false);
  console.log("process.env", process.env);
  const link = `${process.env.REACT_APP_DOMAIN}/share/${messageId}`;
  const fileName = name
    ? `${name.slice(0, 50).split(" ").join("-")}`
    : "Mail Subject ";
  const copyHandler = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const icon = themeIcon();

  return (
    <div>
      {loading && <LoadingOverlay />}
      <div
        className="cursor-default modal flex overflow-y-auto overflow-x-hidden fixed inset-0 bg-gray-700 bg-opacity-75 z-[10] justify-center items-center w-full max-h-full"
        onClick={togglePopup}
      >
        <div
          className="relative p-4 w-full max-w-2xl max-h-full"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between px-4 pt-4 border-b rounded-t dark:border-gray-600">
              <div className="w-full p-4 inline-flex justify-start items-center gap-4">
                <div
                  onClick={copyHandler}
                  title="Copy public link"
                  className="text-center relative items-center flex justify-center rounded-full p-2 w-10 h-10 hover:bg-gray-200 hover:cursor-pointer"
                >
                  <img src={icon.copyIcon} alt="copy icon"></img>
                  {copied && (
                    <span
                      className={`${styles["copy-message"]} !left-0 top-12`}
                    >
                      Copied!
                    </span>
                  )}
                </div>
                <p>{link}</p>
              </div>
              <button
                type="button"
                className="z-[7] text-gray-400 bg-transparent hover:bg-gray-200 hover:text-black rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={togglePopup}
              >
                <IconClose />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 flex flex-col justify-center items-center text-center">
              <div className="w-10/12 inline-flex justify-center items-center gap-6">
                <a
                  rel="noreferrer"
                  href={`mailto:?subject=${fileName}`}
                  target="_blank"
                >
                  <img
                    src={commonIcon.emailIcon}
                    className="size-14 p-1.5"
                    alt="Mail to"
                  ></img>
                  <span className="font-medium text-sm">Mail</span>
                </a>
                <a
                  rel="noreferrer"
                  href={`https://www.facebook.com/dialog/share?display=popup&href=${link}`}
                  target="_blank"
                >
                  <IconFacebook className="size-14" />
                  <span className="font-medium text-sm">Facebook</span>
                </a>
                <a
                  rel="noreferrer"
                  href={`https://www.reddit.com/submit?url=${link}`}
                  target="_blank"
                >
                  <IconReddit className="size-14 pt-[2px]" />
                  <span className="font-medium text-sm">Reddit</span>
                </a>
                <a
                  rel="noreferrer"
                  href={`https://twitter.com/intent/tweet?url=${link}`}
                  target="_blank"
                >
                  <IconX className="size-14 pt-[2px]" />
                  <span className="font-medium text-sm">X</span>
                </a>
                <a
                  rel="noreferrer"
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${link}`}
                  target="_blank"
                >
                  <IconLinkedln className="size-14" />
                  <span className="font-medium text-sm">Linkedln</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePopup;
